import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BehaviourService } from '@pa/lib-spa';
import { ContactType, NotificationUserType, OriginatorType } from '@pa/references/idf';
import { HybridOriginator } from '@pa/sdk/idf';
import { isDirectUser } from 'src/app/utils/utils';

export interface LoginComponentOutput {
    code: string;
}

@Component({
    selector: 'sp-login-component',
    templateUrl: './login.component.html',
})
export class LoginComponent implements OnChanges {
    @Input() originator: HybridOriginator;
    @Output() output = new EventEmitter<LoginComponentOutput>();

    isDirect: boolean = true;
    loading: boolean = true;

    email: string;
    public loginForm = this._fb.group({
        phoneNumberDigits: ['', [Validators.required, Validators.pattern(/^[0-9]{4}$/)]],
    });

    constructor(private _fb: FormBuilder, private behaviourService: BehaviourService) {
        this.behaviourService.behaviour.subscribe((b) => {
            const supportContact = (b.uiConfig?.contacts || []).find((c) => c.type === ContactType.genericSupport);
            if (supportContact) {
                this.email = supportContact.emailAddress;
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['originator'] && this.originator.user !== '') {
            this.loading = false;
            this.isDirect = isDirectUser(this.originator.type, this.originator.verificationUserType);
        }
    }

    submit() {
        this.output.next({ code: this.loginForm.value.phoneNumberDigits });
    }
}
