import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormConfigModule, HeaderFooterModule, SDK_ENV, SERVICE_NAME, SdkModule, UI_TYPE } from '@pa/lib-spa';
import { ServicePortalComponent } from './service.portal.component';
import { BillingModule } from './billing/billing.module';
import { CoreModule } from './core/core.module';
import { InsuranceModule } from './insurance/insurance.module';
import { ServicePortalRoutingModule } from './service.portal.routing.module';
import { environment } from 'src/environments/environment';
import { UserInterfaceType } from '@pa/references';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
    imports: [
        BillingModule,
        BrowserModule,
        CoreModule,
        HttpClientModule,
        InsuranceModule,
        HeaderFooterModule,
        NgbModule,
        FormConfigModule,
        RouterModule,
        ServicePortalRoutingModule,
        MatFormFieldModule,
    ],
    exports: [FormConfigModule, SdkModule],

    declarations: [ServicePortalComponent],
    bootstrap: [ServicePortalComponent],
    providers: [
        { provide: SERVICE_NAME, useValue: environment.serviceName },
        { provide: SDK_ENV, useValue: environment.sdkEnv },
        { provide: UI_TYPE, useValue: UserInterfaceType.insuredSelfServicing },
    ],
})
export class ServicePortalModule {}
