import { Component, OnInit } from '@angular/core';
import { PaymentOption } from '@pa/references';
import { TransactionType } from '@pa/references/idf';

import { AuthService } from 'src/app/core/authentication/authentication.service';
import { InsuranceService } from '../insurance.service';

@Component({
    selector: 'sp-summary',
    templateUrl: './summary.page.html',
})
export class SummaryPage implements OnInit {
    public invoiceLink = '';
    public message = '';

    constructor(private _authService: AuthService, private _insuranceService: InsuranceService) {}

    ngOnInit() {
        const info = this._authService.session;

        switch (info.transactionType) {
            case TransactionType.cancellation:
                this.message = ['Thank you for accepting terms.', 'Your policy is cancelled'].join(' ');
                break;
            default:
                switch (this._insuranceService.quoteState.paymentOption) {
                    case PaymentOption.bankTransfer: {
                        this.message = [
                            'Thank you for accepting terms.',
                            'Once your payment has been received we will process your policy documentation and send you a policy email.',
                        ].join(' ');
                        break;
                    }
                    case PaymentOption.attvest: {
                        this.message = [
                            'Thank you for accepting terms. A member of our team will be in contact with you shortly to discuss your monthly payments.',
                            'If you change your mind, you can still return to your quote and select a different payment option.',
                        ].join(' ');
                        break;
                    }
                    default: {
                        this.message = [
                            'Thank you for accepting terms.',
                            'You will be emailed policy documentation within a few minutes.',
                        ].join(' ');
                    }
                }
                break;
        }
    }
}
