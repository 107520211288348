import { Component, ViewChild, TemplateRef, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { BehaviourService, OriginatorService, THEME_NAME, ThemeService } from '@pa/lib-spa';

import { LoginComponentOutput } from '../components/login.component';

import { AuthService, XeroInvoiceDetails } from '../authentication.service';
import { ContactType, OriginatorType } from '@pa/references/idf';
import { HybridOriginator } from '@pa/sdk/idf';
import { isDirectUser } from 'src/app/utils/utils';

@Component({
    selector: 'sp-callback',
    templateUrl: './callback.page.html',
})
export class CallbackPage {
    @ViewChild('errorDialog', { static: true }) errorDialog: TemplateRef<any> | undefined;

    public submitting = false;
    public email = 'support@precision-autonomy.com';
    public originator: HybridOriginator;
    public errorMessage = '';
    public errorMessageEmail = false;
    public isDirect: boolean = true;

    private _invoiceDetails: XeroInvoiceDetails;
    private themeSlug: string;

    constructor(
        private _authService: AuthService,
        private _behaviourService: BehaviourService,
        private _originatorService: OriginatorService,
        private _themeService: ThemeService,
        private _dialog: MatDialog,
        private ngZone: NgZone,
        private _route: ActivatedRoute,
        private _router: Router
    ) {
        this._route.queryParams.subscribe({
            next: (params) => {
                this._invoiceDetails = {
                    amount: params.amount,
                    currency: params.currency,
                    invoiceId: params.invoiceId,
                    invoiceNo: params.invoiceNo,
                    shortCode: params.shortCode,
                };
            },
        });
        this._themeService.theme.subscribe((theme) => {
            this.themeSlug = theme.slug;
        });
        this._originatorService.originator.subscribe((o) => {
            this.originator = o as HybridOriginator;
            this.isDirect = isDirectUser(this.originator.type, this.originator.verificationUserType);
        });
        this._behaviourService.behaviour.subscribe((b) => {
            const supportContact = (b.uiConfig?.contacts || []).find((c) => c.type === ContactType.genericSupport);
            if (supportContact) {
                this.email = supportContact.emailAddress;
            }
        });
    }

    submit(output: LoginComponentOutput) {
        this.submitting = true;

        const code = output.code;

        this._authService.verifyServiceToken(code, this._authService.session?.token).subscribe({
            next: (validation) => {
                this._authService.invoiceDetails = this._invoiceDetails;
                this._router.navigate(['/quote']);
            },
            error: (err) => {
                this.submitting = false;
                console.error(err);
                this.errorMessage = '';
                this.errorMessageEmail = false;
                if (this.errorDialog) {
                    this.ngZone.run(() => {
                        this._dialog.open(this.errorDialog, {
                            panelClass: THEME_NAME,
                        });
                    });
                }
                if (err.error.result.isLocked) {
                    this.errorMessage = `The link is locked.`;
                    this.errorMessageEmail = true;
                } else if (err.error.result.remainingAttempts) {
                    const pin = !this.isDirect ? 'pin' : 'phone';
                    this.errorMessage = `Incorrect ${pin} number. ${err.error.result.remainingAttempts} more retries before the link will be locked.`;
                }
            },
        });
    }
}
