import { Component, OnInit, Input } from '@angular/core';
import braintreeDropin from 'braintree-web-drop-in';
import { Alert } from '../service.portal.types';
import { AuthService } from '../core/authentication/authentication.service';
import { BillingService } from './billing.service';
import { PaClient } from '@pa/sdk/rmf';

@Component({
    selector: 'sp-billing',
    templateUrl: './billing.template.html',
})
export class BillingComponent implements OnInit {
    @Input() paClient: PaClient | undefined;

    private dropin: any;
    private clientToken: any;

    currencies = ['AUD', 'USD'];
    selectedCurrency = 'AUD';
    hasToken = false;
    billing: any = {};
    alertSuccess: Alert = {
        show: false,
        message: '',
    };
    alertError: Alert = {
        show: false,
        message: '',
    };

    constructor(private billingService: BillingService, private authService: AuthService) {}

    ngOnInit() {
        this.getBraintreeToken();
    }

    getBraintreeToken() {
        const options = {
            currency: this.selectedCurrency,
            paClient: this.paClient ? this.paClient._id : '',
        };

        this.hasToken = false;
        this.billingService.getToken(options).subscribe(
            (tokenData) => {
                this.clientToken = tokenData.clientToken;
                this.hasToken = true;
                this.createDropin(this.clientToken);
            },
            (err) => console.error(err)
        );
    }

    async createDropin(clientToken: string) {
        if (this.dropin) {
            this.dropin.teardown();
        }

        const dropinOpts = {
            authorization: clientToken,
            container: '#braintreeDropIn',
        };

        try {
            this.dropin = await braintreeDropin.create(dropinOpts);
        } catch (err) {
            console.error(err);
        }
    }

    async updatePaymentInfo() {
        let options;
        try {
            options = await this.dropin.requestPaymentMethod();
        } catch (err) {
            console.error(err);
            return;
        }

        this.alertSuccess = {
            show: true,
            message: 'Saving...',
        };

        options.paClient = this.paClient ? this.paClient._id : '';

        this.billingService.addPaymentMethod(options).subscribe(
            (res) => {
                this.alertSuccess = {
                    show: true,
                    message: 'Thanks for updating your payment information!',
                };
            },
            (err) => {
                console.error(err);
                this.alertSuccess = {
                    show: false,
                    message: '',
                };
                this.alertError = {
                    show: true,
                    message: 'Could not update your payment information',
                };
            }
        );
    }
}
