import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, combineLatest } from 'rxjs';

import { Behaviour, FormConfig, Theme } from '@pa/sdk/idf';
import {
    BehaviourSelection,
    BehaviourService,
    FormConfigService,
    OriginatorService,
    ThemeService,
    mapAttachments,
} from '@pa/lib-spa';
import {
    CurrencyCode,
    formatCurrencyAmountByFormatter,
    getCurrencyFormatter,
    Insurer,
    TransactionType,
} from '@pa/references/idf';

import { Attachment, InvoiceResponse } from '../../service.portal.types';
import { AuthService, SessionUserInfo } from '../../core/authentication/authentication.service';
import { documentPoints, InsuranceService } from '../insurance.service';
import { FormlyFormOptions } from '@ngx-formly/core';
import { isDirectUser } from 'src/app/utils/utils';
import { FormatDateConfig } from '@pa/lib-spa/types';

@Component({
    selector: 'sp-invoice-component',
    templateUrl: './invoice.component.html',
    styleUrls: ['./invoice.component.scss'],
})
export class InvoiceComponent {
    @Output() invoiceLoaded = new EventEmitter<InvoiceResponse>();

    showInvoice: boolean = false;
    loadingInvoice: boolean = false;
    public directInsured: boolean = true;
    public invoicePdf: any;
    public premiumBreakdown: any;
    public taxTooltip = 'A full breakdown of the taxes can be found on the Invoice/Credit Note in the links below.';
    public attachments: Attachment[];
    public policyDetail: { [date: string]: FormatDateConfig } = {};
    public detailsName = '';
    public editQuoteUrl = '';
    public cannotBind: boolean;
    public quoteLapsed = false;
    private info: SessionUserInfo;
    effectiveDate: string;
    listClass: string;
    theme: Observable<Theme>;
    behaviour: Behaviour;
    invoicePdfName = 'Invoice';
    netPayable = 'Net Payable to Insurer: ';
    hasPaid: boolean;
    amendEffectiveDateTitle: string;
    showWarning: boolean = false;
    defaultFormConfig?: FormConfig;
    defaultFormBehaviour?: string[];
    private _behaviourSelection: BehaviourSelection;
    public options: FormlyFormOptions = {
        formState: {
            data: {},
        },
    };
    public quoteLapsedMessage: string = '';
    expiryDateExceeded: boolean = false;

    constructor(
        private _authService: AuthService,
        private _insuranceService: InsuranceService,
        private _sanitizer: DomSanitizer,
        private _router: Router,
        private _themeService: ThemeService,
        private _behaviourService: BehaviourService,
        private _formConfigService: FormConfigService,
        private _originatorService: OriginatorService
    ) {
        this.theme = _themeService.theme;
        this.theme.subscribe((theme) => {
            this.listClass = documentPoints[theme.slug] ?? 'fal fa-file-alt fa-md';
        });
        this._behaviourService.behaviour.subscribe((b) => (this.behaviour = b));
    }

    /**
     * Uses provided formatter but converts negatives to parentheses: -200 becomes (200)
     * @param num
     * @param formatter
     */
    prettyFormatNum(num: number, formatter: Intl.NumberFormat) {
        const neg = num < 0;
        if (neg) {
            num = Math.abs(num);
        }
        let result = formatCurrencyAmountByFormatter(num, formatter);
        if (neg) {
            result = `(${result})`;
        }
        return result;
    }

    ngOnInit() {
        this._behaviourSelection = this._originatorService.behaviourSelection;
        this.options.formState.behaviourSelection = this._behaviourSelection;

        combineLatest([this._behaviourService.behaviour, this._formConfigService.formConfig]).subscribe(
            ([behaviour, formConfigs]) => {
                this.defaultFormConfig = formConfigs.find((f) => f.abstract === undefined);
                this.defaultFormBehaviour = this.defaultFormConfig?.behaviours;
            }
        );

        this.info = this._authService.session;
        this.directInsured = isDirectUser(this.info.originatorType, this.info.verificationUserType);

        this.cannotBind = this.info.cannotBind;
        this.quoteLapsed = !!this.info.quoteLapsed;

        if (this.cannotBind) {
            this.loadingInvoice = false;
        }

        if (this.info.isCredit) {
            this.invoicePdfName = 'Credit Note';
            this.netPayable = 'Return Premium: ';
        }
        if (this.info.transactionType === TransactionType.amendment) {
            this.invoicePdfName = 'Amendment ' + this.invoicePdfName;
        }
        this.showInvoice = !this.info.quoteLapsed;
        this.loadingInvoice = true;

        const info = this._authService.getInsuranceInfo();

        this.expiryDateExceeded = !!(
            this._authService.session.holdCoverExpiryDate &&
            new Date() >= new Date(this._authService.session.expiryDate)
        );

        this._insuranceService
            .getInvoice(
                this.info.invoiceId,
                this.info.token,
                this._authService.code,
                this.info.behaviour,
                this.info.isCredit
            )
            .subscribe({
                next: (res) => {
                    if (res.data.netPayable < 0 && this.defaultFormBehaviour?.includes(res.data.behaviour)) {
                        this.netPayable = this.defaultFormConfig.fields.netPayableRefund.label;
                    }

                    const currencyCode = res.data.currencyCode || CurrencyCode.AUD;
                    const currencyFormatter = getCurrencyFormatter(currencyCode, true);

                    if (res.invoicePdf) {
                        this.invoicePdf = this._sanitizer.bypassSecurityTrustResourceUrl(res.invoicePdf);
                    }
                    this.attachments = mapAttachments(res.attachments, this.behaviour, this.info.transactionType);
                    if (res.data) {
                        this.hasPaid = res.data.hasProvisioned === true;
                        const expectedPaidDocument =
                            this.info.transactionType === TransactionType.cancellation
                                ? 'Cancellation Notice'
                                : 'Policy Schedule';
                        this.showWarning =
                            this.hasPaid && !this.attachments.find((file) => file.fileName === expectedPaidDocument);
                        if (this.hasPaid && this._router.url !== '/policy') {
                            this._router.navigate(['/policy']);
                            return;
                        }

                        if (this.behaviour.insurer === Insurer.hssb && this._router.url !== '/policy') {
                            this.showInvoice = false;
                        }
                        const insuredName = res.data.contact?.name;
                        const { total, subTotal: basePrem, totalGrossPremium, totalTax: tax } = res.data;
                        let simpleTaxTotal;
                        let taxBreakdown = [];

                        if (typeof tax === 'object') {
                            taxBreakdown = tax?.filter((t) => !t.hide) ?? [];
                            taxBreakdown.forEach((t) => (t.amount = this.prettyFormatNum(t.amount, currencyFormatter)));
                        } else {
                            simpleTaxTotal = this.prettyFormatNum(tax, currencyFormatter);
                        }

                        let { commission, commissionTax, policyFee, policyFeeTax } = res.data;
                        const commissionRate = this._authService.session.commission;

                        if (commission === undefined && !this.directInsured) {
                            const commissionLineItem = res.data.lineItems?.find(
                                (item) => item.itemCode === 'Commission'
                            );
                            if (commissionLineItem) {
                                commission = commissionLineItem.lineAmount * -1;
                            } else {
                                commission = basePrem * commissionRate;
                            }
                        }
                        if (commissionTax === undefined && !this.directInsured) {
                            const commissionTaxLineItem = res.data.lineItems?.find(
                                (item) => item.itemCode === 'CommissionTax'
                            );
                            if (commissionTaxLineItem) {
                                commissionTax = commissionTaxLineItem.quantity * -1;
                            } else {
                                const commissionTaxRate = this._authService.session.commissionTaxRate;
                                commissionTax = commission * commissionTaxRate;
                            }
                        }
                        const netInsurer = total - (commission || 0) - (commissionTax || 0);

                        this.premiumBreakdown = {
                            insuredName,
                            basePrem: this.prettyFormatNum(basePrem, currencyFormatter),
                            // tax lines already currency formatted
                            taxBreakdown,
                            simpleTaxTotal,
                            gross: this.prettyFormatNum(totalGrossPremium, currencyFormatter),
                            commissionRate: (commissionRate ?? 0) * 100,
                            // always show positive for commission
                            commission: commission
                                ? formatCurrencyAmountByFormatter(Math.abs(commission), currencyFormatter)
                                : '',
                            commissionTaxes: commissionTax
                                ? formatCurrencyAmountByFormatter(Math.abs(commissionTax), currencyFormatter)
                                : '',
                            policyFee: policyFee
                                ? formatCurrencyAmountByFormatter(Math.abs(policyFee), currencyFormatter)
                                : '',
                            policyFeeTax: policyFee
                                ? formatCurrencyAmountByFormatter(Math.abs(policyFeeTax), currencyFormatter)
                                : '',
                            netInsurer: this.prettyFormatNum(netInsurer, currencyFormatter),
                        };
                    }
                    this.loadingInvoice = false;
                    const { timezone } = res.data;
                    let expiryDate = res.data.expiryDate;
                    let amendedDate: string | undefined;
                    switch (info?.transactionType) {
                        case TransactionType.amendment:
                            this.amendEffectiveDateTitle = 'Amendment Effective Date';
                            amendedDate = res.data.amendedDate;
                            break;
                        case TransactionType.cancellation:
                            this.amendEffectiveDateTitle = 'Cancellation Effective Date';
                            amendedDate = res.data.cancellationDate;
                            expiryDate = res.data.cancellationDate;
                            break;
                        default:
                            this.amendEffectiveDateTitle = '';
                            break;
                    }

                    this.policyDetail = {
                        reference: res.data.reference,
                        renewalDate: {
                            date: res.data.renewalDate,
                            policyPeriod: this.behaviour.timePeriodConfig?.policyDateConfig?.inception,
                            timezone,
                        },
                        expiryDate: {
                            date: expiryDate,
                            policyPeriod: this.behaviour.timePeriodConfig?.policyDateConfig?.expiry,
                            timezone,
                        },
                        amendedDate: { date: amendedDate, policyPeriod: undefined, timezone },
                    };
                    this.invoiceLoaded.emit(res);
                },
            });

        const transactionType = this.info.transactionType;
        switch (transactionType) {
            case TransactionType.newBusiness: {
                this.detailsName = 'New Business';
                this.editQuoteUrl = this._insuranceService.getEditLink();
                this.quoteLapsedMessage =
                    'This New Business offer is no longer valid. Please click below to request a new quote';
                break;
            }
            case TransactionType.renewal: {
                this.editQuoteUrl = this._insuranceService.getEditLink();
                this.quoteLapsedMessage = 'This renewal notice is no longer valid as the policy has already lapsed.';
                this.detailsName = transactionType.charAt(0).toUpperCase() + transactionType.slice(1);
                break;
            }
            default: {
                this.detailsName = transactionType.charAt(0).toUpperCase() + transactionType.slice(1);
                break;
            }
        }
    }
}
