import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { AuthService } from './authentication.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
    constructor(private _authService: AuthService, private router: Router) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // NOTE: Use this for dev
        // return true;

        return this._authService.activeSession.pipe(
            map(active => {
                return active ? true : this.router.parseUrl('');
            })
        );
    }
}
