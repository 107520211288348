import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import moment, { Moment } from 'moment-timezone';

import { BehaviourService, THEME_NAME, ThemeService, OriginatorService } from '@pa/lib-spa';
import { PaymentOption, UserInterfaceType, XeroInvoiceStatus } from '@pa/references';
import {
    formatCurrencyAmountByFormatter,
    getCurrencyFormatter,
    InputPlaceholder,
    InstalmentType,
    OriginatorType,
    Tooltip,
} from '@pa/references/idf';

import { InvoiceResponse, Theme, TransactionType } from '../../service.portal.types';
import { AuthService } from '../../core/authentication/authentication.service';
import { InsuranceService } from '../insurance.service';
import { environment } from 'src/environments/environment';
import { Behaviour, Originator, UiConfigRefType } from '@pa/sdk/idf';
import { InvoiceInstalment, PayResponse } from '@pa/sdk/pay';
import { StripeSDK } from '../../utils/paymentGateways/stripe';
import { PaymentGatewayConfig, paymentGatewayConfigMap } from '../../utils/paymentGateways/config';
import { CustomEncoder, prependPay } from 'src/app/service.portal.http.config';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { User } from '@pa/sdk/rmf';
import { isDirectUser } from 'src/app/utils/utils';

const SELECT_INSTALMENTS = 'selectInstalments';
const INCEPTION_DATE = 'inceptionDate';
const AMOUNT_LOADED = 'amountLoaded';

@Component({
    selector: 'sp-quote',
    templateUrl: './quote.page.html',
})
export class QuotePage implements OnInit {
    @ViewChild('attvestDialog', { static: true }) attvestDialog: TemplateRef<any> | undefined;
    @ViewChild('bankTransferDialog', { static: true }) bankTransferDialog: TemplateRef<any> | undefined;
    @ViewChild('confirmDialog', { static: true }) confirmDialog: TemplateRef<any> | undefined;
    @ViewChild('confirmHolDCoverDialog', { static: true }) confirmHolDCoverDialog: TemplateRef<any> | undefined;
    @ViewChild('holdCoverDialog', { static: true }) holdCoverDialog: TemplateRef<any> | undefined;
    @ViewChild('errorDialog', { static: true }) errorDialog: TemplateRef<any> | undefined;

    public acceptTermsForm: FormGroup = new FormGroup({});
    public amendDateName: string;
    public amountDue: number;
    public cannotBind: boolean;
    public confirmationMessage = '';
    public currency: string;
    public fields: FormlyFieldConfig[] = [];
    public holdCoverFields: FormlyFieldConfig[];
    public holdCoverForm: FormGroup = new FormGroup({});
    public holdCoverModel: any = {};
    public instalmentsConfig?: UiConfigRefType<InstalmentType>[];
    public isDirect: boolean;
    public isHybrid: boolean = false;
    public isInvoiceVoidedOrExpired = false;
    public loadingInvoice = true;
    public model: any = {};
    public options: FormlyFormOptions = { formState: {} };
    public originator: Originator;
    public originatorUser: User;
    public paymentOptions: PaymentGatewayConfig[];
    public paymentOptionsDisabled: boolean;
    public renewalInvalid: boolean;
    public selectedPaymentOption: PaymentOption | undefined;
    public showHoldCover: boolean = false;
    public showPaymentOptions: boolean;
    public showQuote = true;
    public submitting = false;
    public tooltip = 'This date is subject to change, or the request be declined at the discretion of the Underwriter.';
    public instalmentTypeEventStatus = false;
    instalmentsEnabled = false;
    isLatestRevision = true;
    isAmdOrCnTransaction = false;

    private _confirmDialogRef: MatDialogRef<TemplateRef<any>>;
    private _confirmHoldCoverDialogRef: MatDialogRef<TemplateRef<any>>;
    private _holdCoverDialogRef: MatDialogRef<TemplateRef<any>>;
    private _theme: Theme;
    private _themeLogo: SafeResourceUrl;
    private behaviour: Behaviour;
    private invoiceId: string;
    private originatorCode: string;
    private timezone: string;
    public insureContact;
    private transactionType: TransactionType;

    fieldListener: BehaviorSubject<string | undefined> = new BehaviorSubject(undefined);
    maxEffectiveDate: Moment;

    get theme() {
        return this._theme;
    }

    get themeLogo() {
        return this._themeLogo;
    }

    constructor(
        private _authService: AuthService,
        private _behaviourService: BehaviourService,
        private _customEncoder: CustomEncoder,
        private _dialog: MatDialog,
        private _http: HttpClient,
        private _insuranceService: InsuranceService,
        private _router: Router,
        private _snackBar: MatSnackBar,
        private _themeService: ThemeService,
        private originatorService: OriginatorService,
        private sanitizer: DomSanitizer
    ) {
        this._behaviourService.behaviour.subscribe((behaviour) => {
            this.behaviour = behaviour;

            const instalmentConfig = behaviour.instalmentConfig;
            const instalmentInterface =
                !instalmentConfig?.userInterface ||
                instalmentConfig.userInterface === UserInterfaceType.insuredSelfServicing;

            this.instalmentsConfig = instalmentConfig?.instalmentOptions
                .map((option) => {
                    const displayName = behaviour.uiConfig?.instalmentTypes?.find((t) => t.type === option.type);
                    return { type: option.type, display: displayName?.display ?? option.type };
                })
                .filter((option) => {
                    if (this._authService.session?.instalmentType) {
                        return option.type === this._authService.session.instalmentType;
                    }
                    return true;
                });
            this.model.showInstalments = instalmentConfig?.enabled && instalmentInterface;

            this._setPaymentOptions(behaviour);
        });
    }

    ngOnInit() {
        this._themeService.theme.subscribe({
            next: (theme) => {
                this._theme = theme;
                this._themeLogo = this.getLogoUrl(this._theme);
            },
        });

        if (this._authService.invoiceDetails) {
            this.amountDue = Number(this._authService.invoiceDetails.amount);
            this.currency = this._authService.invoiceDetails.currency;
        }

        const info = this._authService.session;
        this.model.selectInstalments = !!info.instalmentType;

        // fetch originator details
        this.originatorService.lookup(btoa(info.originatorCode)).subscribe({
            next: (originator) => {
                this.originator = originator;
                this.originatorUser = originator.user as User;
                this.setMaxDateFromConfig();
            },
        });

        if (info.renewalInvalid) {
            this.renewalInvalid = true;
            this.showQuote = false;
        }

        this.cannotBind = !!info.cannotBind || !!info.quoteLapsed;
        this.invoiceId = info.invoiceId;
        this.transactionType = info.transactionType;
        this.originatorCode = info.originatorCode;
        this.timezone = info.timezone;
        this.isLatestRevision = this._authService.session.isLatestRevision;
        this.isAmdOrCnTransaction = [TransactionType.amendment, TransactionType.cancellation].includes(
            this.transactionType
        );

        this.isHybrid = info.originatorType === OriginatorType.intermediaryHybrid;

        this.isDirect = isDirectUser(info.originatorType, info.verificationUserType);

        switch (this.transactionType) {
            case TransactionType.cancellation:
                this.amendDateName = 'When would you like this cancellation to come into effect?';
                this.confirmationMessage =
                    'You are about to cancel this policy, please ensure all details are correct before proceeding.';
                break;
            case TransactionType.amendment:
                this.amendDateName = 'When would you like this amendment to come into effect?';
                this.confirmationMessage =
                    'You are about to amend this policy, please ensure all details are correct before proceeding.';
                break;
            case TransactionType.newBusiness:
                this.amendDateName = 'When would you like this policy to commence?';
                this.confirmationMessage =
                    'You are about to bind this policy, please ensure all details are correct before proceeding.';
                break;
            case TransactionType.renewal:
                this.amendDateName = 'If accepted, this policy will renew on:';
                this.confirmationMessage =
                    'You are about to renew this policy, please ensure all details are correct before proceeding.';
                break;
            default:
                this.amendDateName = '';
                break;
        }

        const today = moment.utc().tz(this.timezone).startOf('day');
        this.maxEffectiveDate = today.clone().add(6, 'weeks');

        if (info.effectiveDate) {
            this.model.inceptionDate = moment.utc(info.effectiveDate).tz(this.timezone);
        }
        if ([TransactionType.cancellation, TransactionType.renewal].includes(info.transactionType)) {
            this.model.disableInceptionDate = true;
            this.maxEffectiveDate = today.clone().add(30, 'days');
        }
        if (info.transactionType === TransactionType.amendment) {
            const maxDate = this.behaviour.uiConfig?.maxAmendmentEffectiveDate ?? 0;
            this.model.disableInceptionDate = maxDate <= 0;
            this.maxEffectiveDate = this.model.inceptionDate.clone().add(maxDate, 'days');
        }
        this.model.maxDate = this.maxEffectiveDate;
    }

    canAcceptTerms() {
        if (!this.isLatestRevision) {
            return false;
        }

        if (this.isDirect) {
            return this.amountDue <= 0;
        } else if (this.isHybrid && !this.isDirect) {
            return this.amountDue <= 0;
        } else {
            return !this.isInvoiceVoidedOrExpired && !this.cannotBind;
        }
    }

    private _initCoverFields() {
        const maxHoldCoverPeriod = this.behaviour.uiConfig?.maxHoldCoverPeriod?.find(
            (hc) => hc.type === this._authService.session?.transactionType
        );

        const { tooltips, placeholders } = this.behaviour.uiConfig ?? {};
        const customTooltip = tooltips?.find((t) => t.type === Tooltip.holdCoverDisclaimer);
        if (customTooltip) {
            this.tooltip = customTooltip.display;
        }
        const customPlaceholder = placeholders?.find((t) => t.type === InputPlaceholder.holdCoverReqDetails)?.display;

        const today = moment.utc().tz(this.timezone).startOf('day');
        if (
            maxHoldCoverPeriod?.value &&
            today.isSameOrBefore(this.model.inceptionDate) &&
            !this._authService.session?.holdCoverExpiryDate &&
            !this.isDirect &&
            this.isLatestRevision
        ) {
            this.showHoldCover = true;
            const isRenewal = this.transactionType === TransactionType.renewal;
            const minDate = isRenewal
                ? moment.utc(this._authService.session?.effectiveDate).tz(this.timezone).add(1, 'days')
                : moment();
            const maxDate = minDate.clone().add(maxHoldCoverPeriod.value, 'days');

            this.holdCoverFields = [
                {
                    key: 'reasons',
                    type: 'textarea',
                    templateOptions: {
                        rows: 3,
                        maxLength: 5000,
                        placeholder: customPlaceholder ?? 'Please provide details of the request',
                        attributes: {
                            'data-testid': 'acceptReasons',
                        },
                        required: true,
                    },
                    wrappers: ['form-field'],
                },
                {
                    type: 'datepicker',
                    key: 'holdCoverExpiryDate',
                    templateOptions: {
                        attributes: {
                            'data-testid': 'holdCoverDate',
                        },
                        placeholder: 'Please confirm a proposed Hold Cover expiry date',
                        required: true,
                        datepickerOptions: {
                            datepickerTogglePosition: 'prefix',
                        },
                    },
                    expressionProperties: {
                        'templateOptions.datepickerOptions.min': (model: any) => minDate,
                        'templateOptions.datepickerOptions.max': (model: any) => maxDate,
                    },
                    wrappers: ['form-field'],
                },
            ];
        }
    }

    private _getFormFields(transactionType: TransactionType): FormlyFieldConfig[] {
        const today = moment().utc().tz(this.timezone);
        const effectiveDate = moment(this._authService.session?.effectiveDate).utc().tz(this.timezone);
        const existingPolicyExpiry = this._authService.session?.existingPolicyExpiry
            ? moment(this._authService.session.existingPolicyExpiry).utc().tz(this.timezone)
            : undefined;
        const datePickerExpressionProperties = {
            'templateOptions.datepickerOptions.min': (model: any) => {
                const minDate = existingPolicyExpiry ? existingPolicyExpiry.clone() : today.clone();
                if (!effectiveDate || (effectiveDate && effectiveDate.isSameOrBefore(minDate))) {
                    return minDate;
                } else if (effectiveDate.isAfter(minDate) && this.transactionType === TransactionType.newBusiness) {
                    // for NB -> if effective date is in future, then min date should be today or existingPolicyExpiry whichever is later
                    return minDate;
                } else {
                    return effectiveDate.clone();
                }
            },
            'templateOptions.disabled': (model: any) => model.disableInceptionDate,
            'templateOptions.datepickerOptions.disabled': (model: any) => model.disableInceptionDate,
            'templateOptions.datepickerOptions.max': (model: any) => model.maxDate,
        };

        if (this._authService.session?.isHoldCover) {
            datePickerExpressionProperties['templateOptions.datepickerOptions.min'] = (model: any) =>
                effectiveDate.clone();
        }

        let dateInputPlaceholder: string;
        switch (transactionType) {
            case TransactionType.cancellation:
                dateInputPlaceholder = 'Cancellation Effective Date';
                break;
            case TransactionType.amendment:
                dateInputPlaceholder = 'Amendment Effective Date';
                break;
            case TransactionType.renewal:
                dateInputPlaceholder = 'Renewal Effective Date';
                break;
            case TransactionType.newBusiness: //  fall through
            default:
                dateInputPlaceholder = 'Inception Date';
                break;
        }

        const isNbRnTransaction = [TransactionType.newBusiness, TransactionType.renewal].includes(transactionType);
        const instalmentsLabel =
            isNbRnTransaction && !this._authService.session?.instalmentType
                ? 'Please select instalment frequency'
                : `Please note the ${isNbRnTransaction ? '' : 'remaining'} Instalment Schedule`;

        return [
            {
                type: 'datepicker',
                className: 'col-md-6',
                key: 'inceptionDate',
                hide: this.isHybrid && !this.isDirect,
                templateOptions: {
                    attributes: {
                        'data-testid': 'inceptionDate',
                    },
                    placeholder: dateInputPlaceholder,
                    required: true,
                    datepickerOptions: {
                        datepickerTogglePosition: 'prefix',
                    },
                    wrapperTitle: this.amendDateName,
                },
                expressionProperties: datePickerExpressionProperties,
                wrappers: ['title', 'form-field'],
                hooks: {
                    onInit: (field) => {
                        const now = moment.utc().tz(this.timezone).startOf('day');
                        this.paymentOptionsDisabled = now > field.formControl?.value;

                        field.formControl?.valueChanges.subscribe({
                            next: (value) => {
                                value = moment(value).tz(this.timezone, true);
                                this.paymentOptionsDisabled = now > value;
                                if (value > this.model.maxDate.clone().add(1, 'day').startOf('day')) {
                                    this.paymentOptionsDisabled = true;
                                }
                                this.fieldListener.next(INCEPTION_DATE);
                            },
                        });
                    },
                },
            },
            {
                type: 'radio-extended',
                className: 'col-md-6',
                key: 'selectInstalments',
                templateOptions: {
                    attributes: {
                        'data-testid': 'selectInstalments',
                    },
                    required: false,
                    wrapperLabel: 'Would you like to pay in instalments? ',
                    options: [
                        { value: true, label: 'Yes' },
                        { value: false, label: 'No' },
                    ],
                },
                wrappers: ['label'],
                hideExpression: '!model.showInstalments',
                defaultValue: false,
                hooks: {
                    onInit: (field: FormlyFieldConfig) => {
                        field.formControl?.valueChanges.subscribe((v) => {
                            if (v) {
                                this.fieldListener.next(SELECT_INSTALMENTS);
                                return;
                            }
                            this.instalmentTypeEventStatus = false;
                        });
                    },
                },
            },
            {
                key: 'instalmentType',
                hideExpression: '!model.selectInstalments',
            },
            {
                type: 'instalments',
                key: 'instalments',
                hideExpression: (model) =>
                    !this.instalmentsConfig?.length ||
                    (isNbRnTransaction && !model.selectInstalments) ||
                    this.loadingInvoice,
                props: {
                    required: false,
                    wrapperLabel: instalmentsLabel,

                    // additional data sent to custom component "instalment"
                    options: this.instalmentsConfig?.map((i) => ({ type: i.type, label: i.display ?? i.type })) ?? [],
                    timezone: this.timezone,
                    disabled: !isNbRnTransaction,

                    // to be implemented by custom component "instalment"
                    getCurrentTab: () => new BehaviorSubject(0),
                    loading: () => {},
                },
                wrappers: ['label'],
                hooks: {
                    onInit: (field: FormlyFieldConfig) => {
                        const { props } = field;
                        const getSchedules = () => {
                            if (
                                (this.model.selectInstalments || (!isNbRnTransaction && !field.hide)) &&
                                typeof this.amountDue === 'number'
                            ) {
                                let currentTabIndex = 0;
                                if (typeof props?.getCurrentTab(false) !== 'object') {
                                    currentTabIndex = props?.getCurrentTab(false) as number;
                                }

                                // FIXME: during AMD/CN instalment type is not required
                                const instalmentType = isNbRnTransaction
                                    ? this.instalmentsConfig?.[currentTabIndex]?.type
                                    : InstalmentType.month;

                                props?.loading(true);
                                this.acceptTermsForm.get('instalments')?.setErrors({ loading: true });

                                this.getInstalments(instalmentType).subscribe((instalmentSchedules) => {
                                    field.formControl?.setValue(instalmentSchedules);
                                    this.model.instalmentType = instalmentSchedules.length ? instalmentType : undefined;
                                    field.hide = !instalmentSchedules.length;
                                    props?.loading(false);
                                    if (!this.instalmentTypeEventStatus) {
                                        setTimeout(() => {
                                            // get and set schedule when tab changes
                                            (props?.getCurrentTab() as Observable<number>)?.subscribe(() =>
                                                getSchedules()
                                            );
                                        }, 0);
                                        this.instalmentTypeEventStatus = true;
                                    }
                                    this.acceptTermsForm.get('instalments')?.setErrors(null);
                                });
                            }
                        };

                        this.fieldListener.subscribe((value) => {
                            // get and set schedule when effective date or select instalment option changes
                            if (![SELECT_INSTALMENTS, INCEPTION_DATE, AMOUNT_LOADED].includes(value)) return;
                            getSchedules();
                        });
                    },
                },
            },
            // PP-8400: hides for all originators
            {
                type: 'input',
                className: 'col-md-6',
                key: 'closingInvoiceRef',
                templateOptions: {
                    attributes: {
                        'data-testid': 'closingInvoiceRef',
                    },
                    placeholder: 'Closing Invoice Reference',
                    tooltip: {
                        content: [
                            'If you do not have a reference number yet, please enter',
                            "something easily identifiable (e.g. today's date) so",
                            'that we can update it later.',
                        ].join(' '),
                    },
                    wrapperTitle: 'Please enter a reference for the closing invoice you will be providing us.',
                },
                hide: true,

                wrappers: ['title', 'form-field'],
            },
        ];
    }

    private _setPaymentOptions(behaviour: Behaviour) {
        const token = this._authService.session?.token;
        const code = this._authService.code;

        const gatewayConfigs = paymentGatewayConfigMap({
            bankTransfer: () => {
                this.selectedPaymentOption = PaymentOption.bankTransfer;
                this._dialog.open(this.bankTransferDialog, {
                    panelClass: THEME_NAME,
                });
            },
            attvest: () => {
                this.confirmationMessage =
                    'Please confirm that you are requesting to organize monthly payments for your policy';
                this.selectedPaymentOption = PaymentOption.attvest;
                this._dialog.open(this.attvestDialog, {
                    panelClass: THEME_NAME,
                });
            },
            stripe: () => this._stripePaymentHandler(token, code),
        });

        const behaviourPaymentOptions = behaviour.accounting.paymentOptions;
        this.paymentOptions = gatewayConfigs
            .filter(({ type }) => behaviourPaymentOptions?.includes(type))
            .sort((a, b) => {
                const iA = behaviourPaymentOptions.indexOf(a.type);
                const iB = behaviourPaymentOptions.indexOf(b.type);

                return (
                    (iA === -1 ? behaviourPaymentOptions.length : iA) -
                    (iB === -1 ? behaviourPaymentOptions.length : iB)
                );
            });
    }

    onInvoiceLoaded(invoiceInfo: InvoiceResponse) {
        this.loadingInvoice = false;
        this.fields = this._getFormFields(this.transactionType);
        this._initCoverFields();

        if (!invoiceInfo) return;

        this.amountDue = invoiceInfo.data.total;
        this.insureContact = invoiceInfo.data.contact;
        this.fieldListener.next(AMOUNT_LOADED);

        // instalments
        const isNbRn = [TransactionType.newBusiness, TransactionType.renewal].includes(this.transactionType);
        if (isNbRn) {
            if (this.amountDue < (this.behaviour?.instalmentConfig?.instalmentMin ?? 0)) {
                this.model.showInstalments = false;
            }
        } else {
            this.model.showInstalments = false;
        }

        const isAfterDueDate =
            invoiceInfo.data?.dueDate && moment().startOf('day').isAfter(moment.utc(invoiceInfo.data.dueDate));
        this.isInvoiceVoidedOrExpired = invoiceInfo.data?.status === XeroInvoiceStatus.Voided || isAfterDueDate;

        const quoteLapsed = this._authService.session?.quoteLapsed;
        this.showPaymentOptions =
            this.isDirect &&
            this.amountDue > 0 &&
            !this.isInvoiceVoidedOrExpired &&
            !quoteLapsed &&
            this.isLatestRevision;

        if (this.isHybrid && !this.isDirect) {
            // hide for Hybrid Intermediary
            this.showPaymentOptions = false;
        }
    }

    getInstalments(instalmentType: InstalmentType) {
        return this._insuranceService.getInstalmentSchedules(
            this.behaviour._id,
            moment(this.model.inceptionDate).tz(this.timezone, true).toISOString(),
            instalmentType,
            this._authService.session.token,
            this.transactionType,
            this._authService.session.clientProposalId,
            this._authService.session.invoiceId,
            this.amountDue
        );
    }

    formatInstalments(instalments: InvoiceInstalment[]) {
        return instalments?.map((instal) => {
            const today = moment.utc().tz(this.timezone).startOf('day');
            let title;
            let centerTitle = true;
            if (instal.sentStatus) {
                title = 'PAID';
            } else if (moment(instal.dueDate).tz(this.timezone).diff(today, 'days') <= 30) {
                title = 'DUE';
            } else {
                centerTitle = false;
                title = `Due: ` + instal.dueDate.substring(0, 10);
            }
            return {
                title,
                centerTitle,
                totalAmount: formatCurrencyAmountByFormatter(
                    instal.totalAmount,
                    getCurrencyFormatter(this._authService.getInsuranceInfo().currency, true)
                ),
            };
        });
    }

    confirm() {
        this._confirmDialogRef = this._dialog.open(this.confirmDialog, { panelClass: THEME_NAME });
    }

    openHoldCover() {
        this._holdCoverDialogRef = this._dialog.open(this.holdCoverDialog, { panelClass: THEME_NAME });
    }

    submit() {
        if (this._confirmDialogRef) {
            this._confirmDialogRef.close();
        }
        this.submitting = true;

        const reqData = {
            amountDue: this.amountDue,
            behaviour: this._authService.session.behaviour,
            code: this._authService.code,
            commission: this._authService.session.commission,
            commissionTaxRate: this._authService.session.commissionTaxRate,
            invoiceId: this.invoiceId,
            instalmentType: this.model.instalmentType,
            paidDate: moment(this.model.inceptionDate).tz(this.timezone, true).toISOString(),
            paymentOption: this.selectedPaymentOption,
            paymentReference: undefined,
            reference: this.model.closingInvoiceRef,
            token: this._authService.session.token,
            originator: this.originatorCode,
        };

        let acceptTerms$: Observable<string>;
        if ([PaymentOption.attvest, PaymentOption.bankTransfer].includes(this.selectedPaymentOption)) {
            acceptTerms$ = this._insuranceService.submitTermsAcceptanceDirect(this.invoiceId, reqData);
        } else {
            acceptTerms$ = this._insuranceService.submitTermsAcceptance(reqData);
        }

        acceptTerms$.subscribe({
            next: (res) => {
                this.submitting = false;
                this._router.navigate(['/summary']);
            },
            error: (err) => {
                this.submitting = false;
                console.error(err);
                if (this.errorDialog) {
                    this._dialog.open(this.errorDialog, { panelClass: THEME_NAME });
                }
            },
        });
    }

    redirectToNewQuote() {
        if (this.originatorCode) {
            window.location.href = `${environment.loginPortalUri}/pp?origin=${this.originatorCode}`;
        }
    }

    submitHoldCover() {
        if (this._confirmHoldCoverDialogRef) {
            this._confirmHoldCoverDialogRef.close();
        }
        this.submitting = true;

        this._insuranceService
            .submitHoldCover(
                this._authService.session.clientProposalId,
                this._authService.session.token,
                this._authService.session.behaviour,
                this.holdCoverModel.holdCoverExpiryDate,
                this.holdCoverModel.reasons
            )
            .subscribe({
                next: (res) => {
                    this.submitting = false;
                    if (this._holdCoverDialogRef) {
                        this._holdCoverDialogRef.close();
                    }
                    this._snackBar.open('Hold cover submitted successfully', 'X', {
                        duration: 5000,
                        panelClass: 'success-snackbar',
                    });
                },
                error: (err) => {
                    this.submitting = false;
                    this._snackBar.open('Hold cover submitted error', 'X', {
                        duration: 5000,
                        panelClass: 'error-snackbar',
                    });
                },
            });
    }

    confirmHoldCover() {
        this._confirmHoldCoverDialogRef = this._dialog.open(this.confirmHolDCoverDialog, { panelClass: THEME_NAME });
    }

    private async _stripePaymentHandler(token: string, code: string) {
        const stripeOption = this.paymentOptions.find((o) => o.type === PaymentOption.stripe);
        stripeOption.loading = true;
        this.selectedPaymentOption = PaymentOption.stripe;
        const stripe = await StripeSDK();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        // TODO: Add endpoint to SDK
        this._http
            .post<PayResponse<any>>(
                prependPay('/v1/payments/stripe/checkout'),
                {
                    currency: this._authService.session.currency,
                    behaviour: this.behaviour.code,
                    effectiveDate: moment(this.model.inceptionDate).tz(this.timezone, true).toISOString(),
                },
                {
                    headers,
                    params: new HttpParams({ encoder: this._customEncoder, fromObject: { code: code, token: token } }),
                }
            )
            .subscribe({
                next: (res) => {
                    const checkoutSession = res.result;
                    stripe.redirectToCheckout({ sessionId: checkoutSession.sessionId });
                    stripeOption.loading = false;
                },
                error: (res) => {
                    this._dialog.open(this.errorDialog, { panelClass: THEME_NAME });
                    stripeOption.loading = false;
                },
            });
    }

    public getLogoUrl(theme: Theme) {
        return theme.logo
            ? this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/svg+xml;base64,${theme.logo}`)
            : undefined;
    }

    setMaxDateFromConfig() {
        if (this.originator?.behaviours) {
            const checkBehaviour = this.originator.behaviours.find(
                (e) => e.code === this._authService.session.behaviour
            );
            const proposalConfigs = checkBehaviour.timePeriodConfig?.['proposalPeriodConfig'];
            if (proposalConfigs) {
                const proposalPeriodConfig = proposalConfigs.find(
                    (e) => e.transactionType === this._authService.session.transactionType
                );
                if (proposalPeriodConfig) {
                    const today = moment.utc().tz(this.timezone).startOf('day');
                    this.maxEffectiveDate = today.clone().add(proposalPeriodConfig.maxEffectivePeriod, 'days');
                    this.model.maxDate = this.maxEffectiveDate;
                }
            }
        }
    }
}
