import { Injectable } from '@angular/core';
import { Idf } from '@pa/sdk/idf';
import { SdkEnv } from '@pa/sdk';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'platform',
})
export class SdkService {
    private readonly _idfService: Idf;

    constructor() {
        this._idfService = new Idf({
            serviceName: environment.serviceName,
            env: environment.sdkEnv as SdkEnv,
            baseUrl: environment.apiBaseUrl.idf,
        });
    }

    get idf() {
        return this._idfService;
    }
}
