import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthModule } from '../authentication/authentication.module';

import { NavBarComponent } from './navBar.component';

@NgModule({
    imports: [
        CommonModule,
        AuthModule,
    ],
    declarations: [
        NavBarComponent
    ],
    exports: [
        NavBarComponent
    ]
})
export class NavBar {}
