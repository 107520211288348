import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';

import { FormatDateComponent, FormComponentsModule } from '@pa/lib-spa';

import { AuthGuard } from '../core/authentication/auth.guard';

import { PolicyPage } from './policy/policy.page';
import { QuotePage } from './quote/quote.page';
import { ReferralPage } from './referral/referral.page';
import { SummaryPage } from './summary/summary.page';

import { InsuranceServiceModule } from './insurance.service.module';
import { CoreModule } from '../core/core.module';
import { InvoiceComponent } from './component/invoice.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IncidentReportPage } from './incident-report/incident-report.page';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { PaymentsPage } from './payments/payments.page';
import { TokenBehaviourResolver } from '../core/tokenBehaviour/token-behaviour-resolver.service';
import { InstalmentScheduleTabs } from './component/instalment.type';

const routes: Routes = [
    { path: 'incident-report', component: IncidentReportPage, canActivate: [AuthGuard] },
    { path: 'policy', component: PolicyPage, canActivate: [AuthGuard] },
    { path: 'quote', component: QuotePage, canActivate: [AuthGuard] },
    {
        path: 'payments',
        component: PaymentsPage,
        resolve: {
            behaviour: TokenBehaviourResolver,
        },
    },
    { path: 'referral', component: ReferralPage },
    { path: 'summary', component: SummaryPage },
];

@NgModule({
    imports: [
        BrowserAnimationsModule,
        CoreModule,
        FormComponentsModule.forRoot(),
        InsuranceServiceModule,
        RouterModule.forRoot(routes),
        MatButtonToggleModule,
        MatDialogModule,
        MatSnackBarModule,
        MatExpansionModule,
        MatTooltipModule,
        MatTabsModule,
        MatTableModule,
        MatCardModule,
        FormatDateComponent,
        FormComponentsModule.forRoot({
            types: [{ name: 'instalments', component: InstalmentScheduleTabs }],
            extras: { resetFieldOnHide: true },
        }),
    ],
    declarations: [
        InstalmentScheduleTabs,
        IncidentReportPage,
        InvoiceComponent,
        PaymentsPage,
        PolicyPage,
        QuotePage,
        ReferralPage,
        SummaryPage,
    ],
    providers: [
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: { dateInput: 'DD-MMM-YYYY' },
                display: {
                    dateInput: 'DD-MMM-YYYY',
                    monthYearLabel: 'MMM YYYY',
                },
            },
        },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false } },
    ],
})
export class InsuranceModule {}
