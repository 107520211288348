import { NotificationUserType } from '@pa/references/idf';
import { OriginatorType } from '../service.portal.types';

const originatorUserType: NotificationUserType[] = [
    NotificationUserType.originatorDefault,
    NotificationUserType.originatorUser,
];
export function isDirectUser(originatorType: OriginatorType, verificationUserType: NotificationUserType): boolean {
    switch (originatorType) {
        case OriginatorType.intermediary:
        case OriginatorType.insuredPolicyManager:
            return false;
        case OriginatorType.intermediaryHybrid:
            return !originatorUserType.includes(verificationUserType);
        default:
            return true;
    }
}
