import { PaymentOption } from '@pa/references';

const paymentOptionTitles: { [P in PaymentOption]: string } = {
    attvest: 'Monthly Instalments via Premium Funding',
    bankTransfer: 'Pay via Direct Bank Transfer',
    bpay: 'BPay',
    stripe: 'Pay via Credit Card',
};

export interface PaymentGatewayConfig {
    title: string;
    type: PaymentOption;
    handler: () => any;
    loading?: boolean;
}
export const paymentGatewayConfigMap = (handlers: { [P in PaymentOption]?: () => any }): PaymentGatewayConfig[] =>
    Object.values(PaymentOption).map((type) => ({
        type,
        title: paymentOptionTitles[type],
        handler: handlers[type],
    }));
