import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {CoreModule} from '../core/core.module';
import {BillingService} from './billing.service';
import {BillingComponent} from './billing.component';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard} from '../core/authentication/auth.guard';

const routes: Routes = [
    {
        path: 'billing',
        canActivate: [AuthGuard],
        children: [
            {path: '', component: BillingComponent}
        ]
    },
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule.forRoot(routes),
        CoreModule
    ],
    declarations: [
        BillingComponent
    ],
    providers: [
        BillingService
    ]
})
export class BillingModule {
}
