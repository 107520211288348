import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { ServicePortalModule } from './app/service.portal.module';
import { environment } from './environments/environment';
import { datadogLogs } from "@datadog/browser-logs";

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(ServicePortalModule)
    .catch(err => console.error(err));

datadogLogs.init({
  clientToken: environment.dataDogToken,
  site: 'datadoghq.com',
  forwardErrorsToLogs: environment.forwardErrorsToLogs,
  sampleRate: 100,
  beforeSend: (log) => {
  },
})
