<div class="row justify-content-center">
    <div *ngIf="!actioned" class="col-lg-6 col-md-6" style="text-align: center;">
        {{actionText}}
        <br /><br />
        <button
            type="button"
            data-testid="confirmReferralAction"
            mat-button
            [disabled]="loading"
            class="btn btn-primary"
            (click)="confirm()"
        >
            <i class="fal fa-spinner fa-spin" *ngIf="loading"></i>
            {{buttonText}}
        </button>
    </div>
    <div class="col-lg-3 col-md-5 col-11" *ngIf="actioned">
        <div class="row justify-content-center">
            <h4>{{message}}</h4>
        </div>
    </div>
</div>
