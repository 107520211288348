import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import _ from 'lodash';

import { InsuranceService } from '../insurance.service';

@Component({
    selector: 'sp-referral',
    templateUrl: './referral.page.html',
})
export class ReferralPage implements OnInit {
    public message = 'Something has gone wrong in the web app';
    public loading = false;
    public actioned = false;
    public actionText = '';
    public buttonText = '';

    private _action = '';
    private _clientProposal = '';
    private _behaviourCode = '';

    constructor(_route: ActivatedRoute, private _insuranceService: InsuranceService) {
        _route.queryParams.subscribe({
            next: (params) => {
                this._action = params.action;
                this._clientProposal = params.clientProposal;
                this._behaviourCode = params.behaviour;

                this.actionText = `Please click the following button to confirm ${
                    this._action === 'accept' ? 'Accepting' : 'Declining'
                } for proposal ${params.clientProposal}:`;
                this.buttonText = _.capitalize(params.action);
            },
        });
    }

    ngOnInit() {}

    confirm() {
        this.loading = true;

        if (this._action === 'accept' || this._action === 'decline') {
            this._insuranceService.actionReferral(this._action, this._clientProposal, this._behaviourCode).subscribe({
                next: (msg) => {
                    this.message = msg;
                    this.loading = false;
                    this.actioned = true;
                },
            });
        } else {
            this.message = 'Unknown action';
            this.loading = false;
            this.actioned = true;
        }
    }
}
