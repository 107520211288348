import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TokenBehaviourResolver } from '../tokenBehaviour/token-behaviour-resolver.service';
import { AuthService } from './authentication.service';

import { LoginComponent } from './components/login.component';
import { SsoComponent } from './sso/sso.component';
import { UnauthorisedComponent } from './unauthorised/unauthorised.component';
import { UnauthenticatedComponent } from './unauthenticated/unauthenticated.component';
import { LoginPage } from './login/login.page';
import { CallbackPage } from './callback/callback.page';
import { MatTooltipModule } from '@angular/material/tooltip';

const routes: Routes = [
    {
        path: '',
        component: LoginPage,
        resolve: {
            behaviour: TokenBehaviourResolver,
        },
    },
    { path: 'sso', component: SsoComponent },
    { path: 'unauthenticated', component: UnauthenticatedComponent },
    { path: 'unauthorised', component: UnauthorisedComponent },
    { path: 'xero', component: CallbackPage },
];

@NgModule({
    imports: [
        CommonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatTooltipModule,
        ReactiveFormsModule,
        RouterModule.forRoot(routes),
        MatTooltipModule,
    ],
    providers: [AuthService, TokenBehaviourResolver],
    declarations: [
        CallbackPage,
        LoginComponent,
        LoginPage,
        SsoComponent,
        UnauthenticatedComponent,
        UnauthorisedComponent,
    ],
})
export class AuthModule {}
