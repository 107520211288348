<!-- Alert Bar -->
<lp-alerts
    [success]="alertSuccess"
    [error]="alertError">
</lp-alerts>
<br>

<!--**********************************************************************************************************-->
<div class="row justify-content-center">
    <div class="col-10">
        <div class="card">
            <div class="card-header d-flex flex-sm-row flex-column">
                <h3 class="p-2 mr-sm-auto">Client Details</h3>
            </div>
            <div class="card-body">
                You are taking out insurance coverage for: <b>{{paClient ? paClient.companyName : ''}}</b>
            </div>
        </div>
        <br>

    <!-- <select name="currency"
            data-ng-model="selectedCurrency"
            ng-options="x for x in currencies"
            ng-change="getBraintreeToken()">
        </select> -->
    </div>
</div>

<!--**********************************************************************************************************-->
<div class="row justify-content-center">
    <div class="col-10">
        <div class="card">
            <div class="card-header d-flex flex-sm-row flex-column">
                <h3 class="p-2 mr-sm-auto">Prepayments</h3>
                <div class="p-2 ml-sm-auto" role="group" aria-label="billingButton">
                    <button type="button" class="btn btn-outline-primary btn-block" (click)="updatePaymentInfo()">
                        Save Billing<br>Information
                    </button>
                </div>
            </div>
            <div class="card-body">
                <div>
                    The information entered here will be used to collect prepayments which will be used for your flight coverage.
                </div>
                <br/>
                <div [ngSwitch]="hasToken">
                    <div *ngSwitchCase="true">
                        <div id="braintreeDropIn"></div>
                    </div>
                    <div *ngSwitchCase="false">
                        Loading payment gateway...
                    </div>
                </div>
            </div>
        </div>
        <br>

    <!-- <select name="currency"
            data-ng-model="selectedCurrency"
            ng-options="x for x in currencies"
            ng-change="getBraintreeToken()">
        </select> -->
    </div>
</div>
