import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../authentication.service';

@Component({
    selector: 'sp-sso',
    templateUrl: './sso.template.html'
})
export class SsoComponent implements OnInit, OnDestroy {
    // session: Subscription;

    constructor(
        private authService: AuthService
    ) {}

    ngOnInit() {
        // this.$state.go('billing');

        // const check = this.authService.checkSession()
        // .pipe(
        //     concatMap(active => active ? of(true) : this.authService.getTokens())
        // );

        // this.session = check.subscribe(
        //     active => active ? this.$state.go('dashboard') : this.$state.go('unauthenticated')
        // );
    }

    ngOnDestroy() {
        // this.session.unsubscribe();
    }
}
