<div class="row justify-content-center" *ngIf="loading">
    <br />
    <br />
    <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Submitting...</span>
    </div>
</div>
<div *ngIf="!loading">
    <form [formGroup]="loginForm" (ngSubmit)="submit()">
        <div class="row justify-content-center">
            <mat-form-field class="col-md-6 col-11">
                <input
                    type="text"
                    matInput
                    [placeholder]="
                        'Please verify ' + (!isDirect ? 'your pin' : 'the last 4 digits of your phone number')
                    "
                    id="phoneNumberDigits"
                    formControlName="phoneNumberDigits"
                    data-testid="verificationCode"
                />
                <mat-error *ngIf="loginForm.hasError('pattern', 'phoneNumberDigits')">
                    Please enter 4 digits only
                </mat-error>
            </mat-form-field>
        </div>
        <div class="row justify-content-center">
            <p data-testid="contactSupport">
                {{ !isDirect ? 'Forgot your pin?' : 'Having trouble?' }} Contact
                <a [href]="'mailto:' + email">{{ email }}</a>
            </p>
        </div>
        <br />
        <div class="row justify-content-center">
            <button
                type="submit"
                id="submitButton"
                data-testid="submitButton"
                class="btn btn-primary col-md-3 col-11"
                mat-button
                [disabled]="!loginForm.valid"
            >
                Next
            </button>
        </div>
    </form>
</div>
