import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Behaviour, HybridOriginator, Originator } from '@pa/sdk/idf';
import { BehaviourService } from '@pa/lib-spa';
import { OriginatorResolver } from '@pa/lib-spa';
import { Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { AuthService } from '../authentication/authentication.service';

@Injectable({
    providedIn: 'any',
})
export class TokenBehaviourResolver  {
    constructor(
        private originatorResolver: OriginatorResolver,
        private behavioursService: BehaviourService,
        private authService: AuthService
    ) {}

    resolve(route: ActivatedRouteSnapshot): Observable<Behaviour> {
        if (!route.queryParamMap.has('token') && !this.authService.session?.token) {
            throw new Error('Missing token parameter.');
        } else {
            // mock a route containing query param "origin", /originators/:code endpoint also accepts token as :code
            const token = route.queryParamMap.get('token') || this.authService.session.token;
            const normalizedRoute = new ActivatedRouteSnapshot();
            normalizedRoute.queryParams = { origin: btoa(token) };
            (this.originatorResolver.resolve(normalizedRoute) as Observable<HybridOriginator>).subscribe((o) => {
                if (o.behaviours.length) {
                    this.behavioursService.set(o.behaviours[0]);
                }
            });
        }
        return this.behavioursService.behaviour.pipe(filter((behavior) => !!behavior)).pipe(first());
    }
}
