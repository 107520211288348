export const environment = {
    "production": true,
    "serviceName": "ServicePortal",
    "sdkEnv": "dev",
    "loginRedirectUri": "https://login.padev.studio",
    "loginPortalUri": "https://login.padev.studio",
    "apiBaseUrl": {
        "cmf": "https://cmf.padev.studio/api",
        "idf": "https://idf.padev.studio/api",
        "pay": "https://pay.padev.studio/api",
        "rmf": "https://rmf.precision-autonomy.com/dev"
    },
    "forwardErrorsToLogs": false,
    "dataDogToken": "pube840dc737eb66b50afe32b1d092c91db",
    "stripeKey": "pk_test_51LZkDGBIXO5GdGufoo00CF9oOx8spd5mG4lSFS65dsa7w5Rg7lcOV4gxDyphPs6nmhdmLUZXV0pNpHFvQ4UOXY0c00et4Wi0bw"
}