import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ErrorComponent } from './error.component';

const routes: Routes = [
    { path: 'error', component: ErrorComponent }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    declarations: [
        ErrorComponent,
    ],
    exports: [
        ErrorComponent
    ]
})
export class ErrorModule {}
