import { NgModule } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthModule } from './authentication/authentication.module';
import { ErrorModule } from './error/error.module';
import { NavBar } from './navBar/navBar.module';
import { TokenBehaviourResolver } from './tokenBehaviour/token-behaviour-resolver.service';
import {
    IDF_ADDRESS,
    CMF_ADDRESS,
    OriginatorModule,
    AlertsModule,
    LoadingModule,
    ThemeModule,
    FormConfigModule,
    SdkModule,
    SERVICE_NAME,
    SDK_ENV,
    UI_TYPE,
} from '@pa/lib-spa';
import { UserInterfaceType } from '@pa/references';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
    imports: [
        AlertsModule,
        AuthModule,
        ErrorModule,
        LoadingModule,
        OriginatorModule,
        ThemeModule,
        NavBar,
        MatFormFieldModule,
    ],
    exports: [AlertsModule, AuthModule, ErrorModule, LoadingModule, NavBar, FormConfigModule, SdkModule],
    providers: [
        TokenBehaviourResolver,
        {
            provide: IDF_ADDRESS,
            useValue: environment.apiBaseUrl.idf,
        },
        {
            provide: CMF_ADDRESS,
            useValue: environment.apiBaseUrl.cmf,
        },
        { provide: SERVICE_NAME, useValue: environment.serviceName },
        { provide: SDK_ENV, useValue: environment.sdkEnv },
        { provide: UI_TYPE, useValue: UserInterfaceType.insuredSelfServicing },
    ],
})
export class CoreModule {}
