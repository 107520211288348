import { ChangeDetectorRef, Component } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { FieldType } from '@ngx-formly/core';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'formly-field-instalment-list',
    templateUrl: './instalment.type.html',
    styleUrls: ['instalment.type.css'],
})
export class InstalmentScheduleTabs extends FieldType {
    currentTabIndex: BehaviorSubject<number> = new BehaviorSubject(0);
    displayedColumns: string[] = ['instalmentIndex', 'dueDate', 'dueAmount'];

    private _selectedIndex: number = 0;
    private _loading: boolean = true;

    get loading() {
        return this._loading;
    }

    get selectedIndex() {
        return this._selectedIndex;
    }

    constructor(private cd: ChangeDetectorRef) {
        super();
    }

    ngOnInit() {
        this.props.getCurrentTab = this.getActiveTab.bind(this);
        this.props.loading = this.setLoading.bind(this);
    }

    tabChanged(tabChangeEvent: MatTabChangeEvent): number {
        this.currentTabIndex.next(tabChangeEvent.index);
        return tabChangeEvent.index;
    }

    setLoading(value: boolean) {
        this._loading = value;
        this.cd.detectChanges();
    }

    getActiveTab(asObservable: boolean = true) {
        return asObservable ? this.currentTabIndex : this.currentTabIndex.value;
    }
}
