import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { Theme } from '@pa/sdk/idf';
import { THEME_NAME, ThemeService } from '@pa/lib-spa';

@Component({
    selector: 'sp-root',
    templateUrl: './service.portal.template.html',
})
export class ServicePortalComponent implements OnInit {
    version = require('process').env.npm_package_version;
    theme: Observable<Theme>;
    themeName = THEME_NAME;

    constructor(private themeService: ThemeService, private titleService: Title) {
        this.theme = themeService.theme;
        this.theme.subscribe((theme) => {
            titleService.setTitle(`${theme.name} Service Portal`);
        });
    }

    ngOnInit() {}
}
