import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { PayResponse } from '../service.portal.types';
import { prependPay } from '../service.portal.http.config';

@Injectable()
export class BillingService {
    private readonly headers = new HttpHeaders({
        'Content-Type': 'application/json'
    });

    constructor(private http: HttpClient) {}

    getToken(options: any) {
        return this.http.post<PayResponse<any>>(prependPay('/v1/token'), options, { headers: this.headers })
        .pipe(
            map(res => res.result)
        );
    }

    addPaymentMethod(options: any) {
        return this.http.put<PayResponse<any>>(prependPay('/v1/customer'), options, { headers: this.headers })
        .pipe(
            map(res => res.result)
        );
    }
}
