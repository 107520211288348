<br />
<div class="row justify-content-center" *ngIf="submitting">
    <br />
    <br />
    <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>
<div *ngIf="!submitting && !apiOriginator">
    <sp-login-component (output)="submit($event)" [originator]="originator"></sp-login-component>
</div>

<ng-template #errorDialog>
    <mat-dialog-content>
        <div class="row text-center">
            <p *ngIf="apiOriginator">
                Invalid Originator. Please contact <a [href]="'mailto:' + email">{{email}}</a> for assistance.
            </p>
            <div *ngIf="!apiOriginator">
                <p *ngIf="!errorMessage">
                    Your link has expired or the {{ !isDirect ? 'pin' : 'phone'}} number does not match the one we have
                    recorded.
                    <br />
                    Please contact <a [href]="'mailto:' + email">{{email}}</a> for assistance.
                </p>
                <div *ngIf="errorMessage">
                    {{errorMessage}}
                    <br />
                    <p *ngIf="errorMessageEmail">Please contact <a [href]="'mailto:' + email">{{email}}</a> for
                        assistance.
                    </p>
                </div>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>