import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../../core/authentication/authentication.service';
import { Theme } from '@pa/sdk/idf';
import { ThemeService } from '@pa/lib-spa';

import { InsuranceService, listPoints } from '../insurance.service';
import { originatorTypes, TransactionType } from '@pa/references/idf';

@Component({
    selector: 'sp-policy',
    templateUrl: './policy.page.html',
})
export class PolicyPage implements OnInit {
    theme: Observable<Theme>;
    listClass: string;
    pageTitle: string = 'Your policy is ready!';

    showPdf: boolean;
    public invoiceLink = '';

    constructor(
        private _authService: AuthService,
        private themeService: ThemeService,
        private _insuranceService: InsuranceService
    ) {
        this.theme = themeService.theme;
        this.theme.subscribe((theme) => {
            this.listClass = listPoints[theme.slug];
        });
    }

    ngOnInit() {
        const info = this._authService.getInsuranceInfo();
        switch (info.transactionType) {
            case TransactionType.amendment:
                this.pageTitle = 'Your amended policy is ready!';
                break;
            case TransactionType.cancellation:
                this.pageTitle = 'This policy has been cancelled.';
        }
        this.invoiceLink = info.invoiceLink;
        this.showPdf = info.originatorType === originatorTypes.intermediary;
    }
}
