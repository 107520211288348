<div *ngIf="submitting">
    <div class="row justify-content-center">
        <div class="spinner-border text-primary" role="status"></div>
    </div>
    <div class="row justify-content-center my-3">
        <h4>Processing Payment, Please do not close window</h4>
    </div>
</div>

<ng-template #errorDialog>
    <mat-dialog-content>
    <div class="row text-center">
        <p>
            An error has occurred. A member of our team has been notified and will be in contact shortly.
            <br />
            In the meantime, please feel free to contact <a [href]="'mailto:' + supportEmail">{{supportEmail}}</a>
        </p>
    </div>
</mat-dialog-content>
</ng-template>
