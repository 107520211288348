import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { BehaviourService } from '@pa/lib-spa';

import { prependIdf, CustomEncoder } from '../../service.portal.http.config';
import { PaClient, IdfResponse, VerifiedServiceToken, OneTimeLinkValidation } from '../../service.portal.types';

export interface SessionUserInfo extends VerifiedServiceToken {
    token: string;
    code: string;
    isHoldCover?: boolean;
}

const sessionUserInfo = 'userInfo';

export interface XeroInvoiceDetails {
    amount: string;
    currency: string;
    invoiceId: string;
    invoiceNo: string;
    shortCode: string;
}

/**
 * Authentication Service
 *
 * Stores the code (phone digits) locally so that the value is lost on refresh
 * or if the user navigates away i.e. only valid while the user is here.
 *
 * Stores the token in session memory so that the user can reauth if they
 * navigate away and come back in the same session.
 */
@Injectable()
export class AuthService {
    private _activeSession = new BehaviorSubject<boolean>(false);
    public activeSession = this._activeSession.asObservable();

    private _code: string;
    private _invoiceDetails: XeroInvoiceDetails;

    get code(): string {
        return this._code || this.getInsuranceInfo()?.code;
    }

    get session(): SessionUserInfo | undefined {
        return JSON.parse(sessionStorage.getItem(sessionUserInfo));
    }

    get invoiceDetails(): XeroInvoiceDetails {
        return this._invoiceDetails;
    }

    set invoiceDetails(details: XeroInvoiceDetails) {
        this._invoiceDetails = details;
    }

    constructor(
        private http: HttpClient,
        private _customEncoder: CustomEncoder,
        private behaviourService: BehaviourService
    ) {
        this.checkSession();

        let firstBehaviourSet = true;
        behaviourService.behaviour.subscribe((behaviour) => {
            if (behaviour._id && firstBehaviourSet) {
                sessionStorage.setItem('behaviour', JSON.stringify(behaviour));
                firstBehaviourSet = false;
            }
        });
        const b = JSON.parse(sessionStorage.getItem('behaviour'));
        if (b) {
            behaviourService.set(b);
        }
    }

    verifyServiceToken(code: string, token: string) {
        this._code = code;

        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            params: new HttpParams({
                encoder: this._customEncoder,
                fromObject: {
                    code: code,
                    token: token,
                },
            }),
        };

        return this.http
            .get<IdfResponse<VerifiedServiceToken>>(prependIdf('/v1/auth/serviceTokens/verify'), options)
            .pipe(
                map((res) => {
                    const verifiedToken = res.result as VerifiedServiceToken;
                    const sessionInfo: SessionUserInfo = {
                        token,
                        code,
                        isHoldCover: !!verifiedToken.holdCoverExpiryDate,
                        ...verifiedToken,
                    };
                    sessionStorage[sessionUserInfo] = JSON.stringify(sessionInfo);
                    this._activeSession.next(true);

                    return res.result as VerifiedServiceToken;
                })
            );
    }

    checkSession(): boolean {
        const session = sessionStorage.getItem(sessionUserInfo) ? true : false;
        this._activeSession.next(session);
        return session;
    }

    destroySession() {
        sessionStorage.clear();

        this._activeSession.next(false);
    }

    getClientInfo(): PaClient {
        const info = JSON.parse(sessionStorage.getItem(sessionUserInfo) || '').paClient;

        return info ? info : {};
    }

    getPaymentInformation() {}

    getInsuranceInfo(): SessionUserInfo | undefined {
        const info = sessionStorage.getItem(sessionUserInfo);

        return info ? JSON.parse(info) : undefined;
    }
}
