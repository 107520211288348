import { Component, ViewChild, TemplateRef, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { BehaviourService, OriginatorService, THEME_NAME, ThemeService } from '@pa/lib-spa';

import { ContactType, OriginatorType, ServiceTokenType } from '../../../service.portal.types';

import { AuthService } from '../authentication.service';
import { LoginComponentOutput } from '../components/login.component';
import { HybridOriginator } from '@pa/sdk/idf';
import { isDirectUser } from 'src/app/utils/utils';

@Component({
    selector: 'sp-login-page',
    templateUrl: './login.page.html',
})
export class LoginPage {
    @ViewChild('errorDialog', { static: true }) errorDialog: TemplateRef<any> | undefined;

    public submitting = false;
    public email = 'support@precision-autonomy.com';
    public originator: HybridOriginator;
    public errorMessage = '';
    public errorMessageEmail = false;
    public isDirect: boolean = true;
    public apiOriginator: boolean = false;

    private _code: string | undefined;
    private _token = '';
    private themeSlug: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private dialog: MatDialog,
        private authService: AuthService,
        private ngZone: NgZone,
        private _originatorService: OriginatorService,
        private _themeService: ThemeService,
        private _behaviourService: BehaviourService
    ) {
        this.route.queryParams.subscribe({
            next: (params) => {
                this._token = params?.token || '';
                this._code = params?.code;
            },
        });
        this._themeService.theme.subscribe((theme) => {
            this.themeSlug = theme.slug;
        });
        this._originatorService.originator.subscribe((o) => {
            this.originator = o as HybridOriginator;
            this.isDirect = isDirectUser(this.originator.type, this.originator.verificationUserType);

            if (this.originator.type === OriginatorType.api) {
                this.apiOriginator = true;
                this._openErrorDialog();
            } else {
                this.apiOriginator = false;
            }
        });
        this._behaviourService.behaviour.subscribe((b) => {
            const supportContact = (b.uiConfig?.contacts || []).find((c) => c.type === ContactType.genericSupport);
            if (supportContact) {
                this.email = supportContact.emailAddress;
            }
        });
    }

    ngOnInit() {
        if (this._code && this._code !== '') {
            this.submit({ code: this._code });
        }
    }

    submit(event: LoginComponentOutput) {
        this.submitting = true;

        const code = event.code;

        this.authService.verifyServiceToken(code, this._token).subscribe({
            next: (validation) => {
                switch (validation.type) {
                    case ServiceTokenType.incidentReport: {
                        this.router.navigate(['/incident-report']);
                        break;
                    }
                    case ServiceTokenType.quoteProposal:
                        this.router.navigate(['/quote']);
                        break;
                    case ServiceTokenType.quoteClient:
                        this.router.navigate(['/quote']);
                        break;
                    case ServiceTokenType.policyClient:
                        this.router.navigate(['/policy']);
                        break;
                    case ServiceTokenType.policyAdmin:
                        break;
                    default: {
                        const _check: never = validation.type;
                    }
                }
            },
            error: (err) => {
                this.submitting = false;
                // console.error(err);
                this.errorMessage = '';
                this.errorMessageEmail = false;
                this._openErrorDialog();

                if (err.error.result.isLocked) {
                    this.errorMessage = `The link is locked.`;
                    this.errorMessageEmail = true;
                } else if (err.error.result.remainingAttempts) {
                    const pin = !this.isDirect ? 'pin' : 'phone';
                    this.errorMessage = `Incorrect ${pin} number. ${err.error.result.remainingAttempts} more retries before the link will be locked.`;
                }
            },
        });
    }

    private _openErrorDialog() {
        if (this.errorDialog) {
            this.ngZone.run(() => {
                this.dialog.open(this.errorDialog, {
                    panelClass: THEME_NAME,
                });
            });
        }
    }
}
