<div class="row justify-content-center">
    <h4>View incident report</h4>
</div>
<div class="row justify-content-center">
    <div class="row" *ngIf="loading">
        <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div *ngIf="!loading">
        <div class="row">
            <table class="insured-details">
                <tr>
                    <td>Insured Name:</td>
                    <td>{{ paClient.companyName }}</td>
                </tr>
                <tr>
                    <td>Policy Reference:</td>
                    <td>{{ clientPolicy.reference }}</td>
                </tr>
                <tr>
                    <td>Period of Insurance:</td>
                    <td>
                        <pa-format-date [dateConfig]="clientPolicy.renewalDate"></pa-format-date> -
                        <pa-format-date [dateConfig]="clientPolicy.expiryDate"></pa-format-date>
                    </td>
                </tr>
                <tr>
                    <td>Date of Loss:</td>
                    <td>
                        <pa-format-date [dateConfig]="dateOfLoss"></pa-format-date>
                    </td>
                </tr>
            </table>
        </div>
        <br />
        <h5>Documentation (Downloadable links):</h5>
        <div class="row">
            <ul class="text-left fa-ul">
                <li *ngFor="let document of documents">
                    <span class="fa-li"><i [ngClass]="listClass"></i></span
                    ><a [href]="document.url" target="_blank"> {{ document.display }}</a>
                </li>
            </ul>
        </div>

        <div *ngIf="attachments.length">
            <br />
            <h5>View Attachments</h5>
            <div class="row">
                <ul class="text-left fa-ul">
                    <li *ngFor="let attachment of attachments">
                        <span class="fa-li"><i [ngClass]="listClass"></i></span
                        ><a [href]="attachment.url" target="_blank"> {{ attachment.display }}</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
