import { environment } from '../environments/environment';
import { Injectable } from '@angular/core';
import { HttpUrlEncodingCodec } from '@angular/common/http';

const idfApiBaseUrl = environment.apiBaseUrl.idf;
const payApiBaseUrl = environment.apiBaseUrl.pay;
const rmfApiBaseUrl = environment.apiBaseUrl.rmf;

export function prependIdf(uri: string) { return idfApiBaseUrl + uri; }
export function prependPay(uri: string) { return payApiBaseUrl + uri; }
export function prependRmf(uri: string) { return rmfApiBaseUrl + uri; }

/**
 * This is a custom encoder for URLs because the Angular default one is bugged
 * and does not encode '+' correctly. encodeURIComponent is a native JS/TS function
 * See https://github.com/angular/angular/issues/18261
 * When this issue is closed this custom encoder can be deprecated.
 */
@Injectable({
    providedIn: 'root'
})
export class CustomEncoder extends HttpUrlEncodingCodec {
    encodeKey(key: string): string {
        return encodeURIComponent(key);
    }

    encodeValue(value: string): string {
        return encodeURIComponent(value);
    }
}
