<div class="row justify-content-center">
    <h4>{{pageTitle}}</h4>
</div>

<div class="row justify-content-center">
    <br />
    <br />
    <sp-invoice-component></sp-invoice-component>
    <br />
</div>
